<template>
  <div class="home">
    <header>
      <div class="content-wrap">
        <h1>阿茄思特</h1>
      </div>
    </header>
    <main class="content-wrap">
      <div class="introduce block">
        <h2>一切从简，开启调整之路</h2>
        <p>AdjustRd将提供简约、强大、高体验的APP。为此我们进行了庞大的布局设计，独立的用户中心，支付平台，用户应用中心，数据处理中台，开放API，第三方程序管理系统等，一切都是为了可以达到高度一致的体验和前所未有的程序研发效率。</p>
      </div>
      <div class="product-list block">
        <h2>产品列表</h2>
        <ul class="product-list">
          <li>
            <h3>枇杷村APP</h3>
            <p>为了方便广大程序员更方便的获取最新的面试题和技术咨询，AdjustRd自研了枇杷村APP。APP提供在线面试题阅读和专项练习服务，通过阅读和练习可不断的学习和巩固技术。</p>
            <a href="http://pipa.adjustrd.com">查看详情</a>
          </li>
          <li>
            <h3>CodeRd</h3>
            <p>为了解决项目规范难，企业基础工程无法定制化，开发效率低的问题。2019年我们上线了CodeRd（原懒猴子CG）。CodeRd是一个源代码极速开发平台，准确来说，我们更希望他能成为一个可定制化的基础工程平台。无论你要做开发一套怎样的系统，都可以在这里找到一套合适的基础工程，这样我们在开发过程中才能真正的做到仅关心业务即可。</p>
            <a href="http://coderd.adjustrd.com">查看详情</a>
          </li>
          <li>
            <h3>Eva系开源框架</h3>
            <p>当你需要开发一个网站的后台运营系统，或者开发一套企业内部的管理系统，那么你可能需要权限控制，并且还需要一套简洁严谨的UI。Eva因此而诞生！Eva是一款权限管理系统开源框架，通过CodeRd完成了技术栈和功能模块的在线定制化。Eva包含SpringBoot和SpringCloud两个版本。</p>
            <a href="http://eva.adjustrd.com">查看详情</a>
          </li>
          <li>
            <h3>WallE系开源框架</h3>
            <p>当你在开发一个互联网项目时可能需要对接支付、短信、邮件甚至是第三方的系统。还有可能缺少互联网项目的开发经验导致安全、性能都方面处理的不到位。因此WallE系开源框架诞生，WallE是一个互联网系统后台研发解决方案，通过CodeRd完成了技术栈和功能的在线定制化。你可以选择一些互联网项目常用的功能进行快速集成。</p>
            <a href="http://walle.adjustrd.com">查看详情</a>
          </li>
        </ul>
      </div>
      <div class="order block">
        <h2>项目定制</h2>
        <p>
          阿茄思特承接项目外包，我们有企业内部数据系统、小程序、公众号、PC站点等丰富的研发经验。联系方式（微信）：sdanfy
        </p>
      </div>
    </main>
    <footer>
      <img src="/icon-police.png"><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021010010号 @2021 阿茄思特</a>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
$header-height: 60px;
$primary-color: dodgerblue;
.home {
  .content-wrap {
    width: 1180px;
    margin: 0 auto;
  }
  header {
    background: dodgerblue;
    height: $header-height;
    line-height: $header-height;
    h1 {
      height: $header-height;
      color: #fff;
    }
  }
  footer {
    text-align: center;
    font-size: 14px;
    margin-top: 80px;
    padding: 20px 0;
    background-color: #ddd;
    img {
      vertical-align: middle;
      margin-right: 6px;
    }
    a {
      vertical-align: middle;
      color: #777;
      text-decoration: none;
    }
  }
  .product-list {
    list-style: none;
    padding: 0;
  }
  h2 {
    margin-top: 30px;
    font-size: 26px;
  }
  .block {
    background-color: #fff;
    padding: 40px 60px;
    margin-top: 40px;
    font-size: 18px;
    line-height: 30px;
    a {
      color: $primary-color;
    }
  }
}
</style>
